<script setup lang="ts">
export type Color = 'gray' | 'red' | 'yellow' | 'green' | 'blue' | 'indigo' | 'purple' | 'pink' | 'orange';

const props = withDefaults(
  defineProps<{
    color: Color;
    size?: 'sm' | 'md';
  }>(),
  {
    color: 'blue',
    size: 'md',
  },
);
const getColor = computed(() => {
  switch (props.color) {
    case 'gray':
      return 'bg-gray-100 text-gray-900';
    case 'red':
      return 'bg-red-200 text-red-800';
    case 'yellow':
      return 'bg-yellow-100 text-yellow-800';
    case 'green':
      return 'bg-green-100 text-green-800';
    case 'blue':
      return 'bg-blue-100 text-blue-800';
    case 'indigo':
      return 'bg-indigo-100 text-indigo-800';
    case 'purple':
      return 'bg-purple-100 text-purple-800';
    case 'pink':
      return 'bg-pink-100 text-pink-800';
    case 'orange':
      return 'bg-orange-100 text-orange-800';
    default:
      return 'bg-blue-100 text-blue-800';
  }
});

const getSize = computed(() => {
  switch (props.size) {
    case 'sm':
      return 'px-1.5 py-1 text-[8px]';
    case 'md':
      return 'px-2 py-1 text-xs';
    default:
      return 'px-2 py-1 text-xs';
  }
});
</script>

<template>
  <span :class="[getColor, getSize]" class="inline-flex items-center rounded-md font-medium">
    <slot />
  </span>
</template>
